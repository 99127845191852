import React, {useState,useEffect} from "react";
import { graphql, useStaticQuery } from "gatsby"
import { connect } from "react-redux";
import { Link, useLocation } from "@reach/router";
import Formulario from '../Contact.jsx'
import {group_Unities} from '../../helpers/helper.developments'
import {findEnvironments, getUnitiesWithCorrectRealAddress} from '../../helpers/helper.unities'
import Loading from '../../components/Loading'
import $ from 'jquery' // important: case sensitive.
import { useGetUnitiesQuery, useGetDevelopmentDetailQuery } from "../../redux/middlewareTokkoApi/developments.js";
import useQueryDevelopment from "../../hooks/useQueryDevelopment.js";
import useQueryUnities from "../../hooks/useQueryUnities.js";
import { adapt_unities_data } from "../../helpers/helper.mediacore.jsx";
import { getShortOperation } from "../../helpers/helper.properties.jsx";

const Units = (props) => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
            template_version
            name
            proFeatures{
                lowPrice
            }
            design {
                icons
            }
            keys{
                tokko
            }
          }
    }`)



    const {template_version,proFeatures} = realEstate
    const {lowPrice} = proFeatures
    const MediaPro = template_version === 'PRO' ? true : false;

    const location = useLocation()

    const [buttonsFilters,setButtonsFilters] = useState([])

    const [group,setGroup] = useState([])
    const [load,setLoad] = useState(false)

    const {unities, id} = props
    const {development} = props
    const {loading} = props

    const {  allUnitiesData, isLoading: isLoadingUnities, isMediacore } = useQueryUnities(id)
    const { allDevelopmentData, isLoading } = useQueryDevelopment(id)

    const [filters,setFilters] = useState(0)
    const [isArrowClicked, setIsArrowClicked] = useState(false)

    const query = new URLSearchParams(location.search);
    const idUnidades = query.get('unidades')?.split("-") // Obtengo IDS por URL

    const [booleanScroll,setBooleanScroll] = useState(false)

    useEffect(() => {
        if(allUnitiesData){
            if(realEstate.keys.tokko){
                setGroup(group_Unities(allUnitiesData,filters,idUnidades))
            }
            else{
                setGroup((adapt_unities_data(allUnitiesData.filter(unity => (filters === 0 || filters === unity.room_amount )&& unity.is_active === true))))
            }
            setLoad(true)
        }
    },[allUnitiesData,filters])

    useEffect(() => {
        // console.log(getUnitiesWithCorrectRealAddress(unities))
        if(realEstate.keys.tokko){
            setButtonsFilters(findEnvironments(allUnitiesData,true))
        }
        else{
            setButtonsFilters(findEnvironmentsMediacore(allUnitiesData))
        }
    },[allUnitiesData,group,filters])

    useEffect(() => {
        if(idUnidades?.length > 0 && !booleanScroll && !loading){
            if($("#unidades")){
                $('html, body').animate({
                    scrollTop: $("#unidades").offset()?.top
                },
                500);
                setBooleanScroll(true)
            }
        }
    },[idUnidades,loading])

console.log(group)

    const findEnvironmentsMediacore = (unities) => {
        const response = []
        if(unities){
            for (const unity of unities) {
                if(!response.find(res => res === unity.room_amount)){
                    response.push(unity.room_amount)
                }
            }
        }
        return response
    }

    const updateFilter = (value) => {
        if(value===filters){return setFilters(0)}
        return setFilters(value)
    }


    return (
        <section class={"ficha-units " } id="unidades">
            <div className="container-fluid">
                <>
                    <div className={"row intro " + (!isLoadingUnities && group?.length === 0 ? 'd-none' : '')}>
                        <div className="col-lg-2">
                            <h2 className="section-name">Unidades</h2>
                        </div>
                        <div className="col-lg-10">
                            <p>Gran variedad de unidades que se adaptan <br className="d-none d-lg-block" /> a todas tus necesidades y requerimientos de búsqueda.</p>
                        </div>
                        <div className="col-12">
                            <div className="d-flex flex-wrap justify-content-center mt-5">
                            {isLoadingUnities 
                            ?   [1,2,3,4].map((u) => (<div className={"tag-filter me-3 mb-3 skeleton overflow-hidden"}> XXXXX</div>))
                            :   <>
                                    {
                                        buttonsFilters.length > 1 && <div className={"tag-filter proyect-bg me-3 mb-3 " + (filters === 0 ? ' active ' : '')} onClick={() => updateFilter(0)}> Todos</div>
                                    }
                                    {
                                        buttonsFilters.length > 1
                                        && buttonsFilters.map(element => (
                                            // <div className={"tag-filter proyect-bg me-3 mb-3 " + (findEnvironments(allUnitiesData,element) ? '' : ' d-none ' ) + (filters === element ? ' active ' : '')} onClick={() => updateFilter(element)}>{element} Ambiente{element > 1 && 's'}</div>
                                            <div className={"tag-filter proyect-bg me-3 mb-3 " + (true ? '' : ' d-none ' ) + (filters === element ? ' active ' : '')} onClick={() => updateFilter(element)}>{element} Ambiente{element > 1 && 's'}</div>
                                        ))
                                    } 
                                </>
                            }
                            </div>
                        </div>
                    </div>    
                    <div className={"row " + (!isLoadingUnities && group?.length === 0 ? 'd-none' : '')}>
                        <div className="col-lg-8 offset-lg-2">
                            {isLoadingUnities 
                            ?   <div className="skeleton w-100" style={{height:"2rem"}}></div>    
                            :   
                                <>
                                    {group && load ? 
                                        group.map((item,index) => (
                                            <div className={"unity"}style={{zIndex:"5"}}>
                                                <div className="head-row row collapsed"  data-bs-toggle="collapse" onClick={() => setIsArrowClicked(!isArrowClicked)} data-bs-target={"#collapseExample"+index} aria-expanded="false" aria-controls={"collapseExample"+index} key={index} >
                                                    <div className="col-lg-2 col-2 pe-0 pe-lg-2">
                                                        <span className="unity-font">{item.floor}</span>
                                                    </div>
                                                    <div className="col-lg-1 px-0 col-3 d-none d-lg-block" style={{textAlign:'center'}}>
                                                        <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Ambientes</span>
                                                    </div>
                                                    <div className="col-lg-2 col-3 d-none d-lg-block" style={{textAlign:"center"}}>
                                                        <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>{isMediacore ?  "Disponible" : "Sup. cubierta"} </span>
                                                    </div>
                                                    <div className="col-lg-2 col-2 px-0 px-lg-2" style={{textAlign:"center"}}>
                                                        <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Sup. Total </span>
                                                    </div>
                                                    <div className="col-lg-2 col-3 d-lg-block" style={{textAlign:'center'}}>
                                                        <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Operación</span>
                                                    </div>
                                                    <div className="col-lg-2 col-3" style={{textAlign:"center"}}>
                                                        <span className="unity-font sub-title" style={{minWidth:'5rem',textAlign:"center"}}>Precio </span>
                                                    </div>
                                                    <div className="col-lg-1 col-1" style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                                                    <i className={'icon-arrow'}></i>
                                                </div>
                                                </div>
                                                <div className="close-deployable collapse pb-3" id={"collapseExample"+index}>
                                                    {/* {item.unities.sort((a, b) => a.address.split(" ")[1]?.localeCompare(b.address.split(" ")[1])).map((element,index) => (                // SOLUCIÓN                          */}
                                                    {item.unities.map((element,index) => (               
                                                        <div key={index}>
                                                            <>
                                                                <a className={element.status === 2 || element.availability.toLowerCase() === 'disponible' ? '' : "disabled-row"} target="_blank" href={element.status === 2 || element.availability.toLowerCase() === 'disponible' ? ("/propiedad/" + element.operations[0]?.operation_type?.toLowerCase() + "/" + element.fake_address.toLowerCase().replaceAll(" ","-") + "/" + element.id) : ''}>
                                                                    <div className={"unity-row unit row " + (element.status === 2 || element.availability.toLowerCase() === 'disponible' ? '' : 'disabled')} >
                                                                        <div className="col-lg-2 col-2 pe-0 pe-lg-2">
                                                                            <span className={"ms-lg-0 unity-yellow-font montserrat-regular proyect-color"}>{element.address}</span>
                                                                        </div>
                                                                        <div className="col-lg-1 px-0 col-3 d-none d-lg-block" style={{textAlign:'center'}}>
                                                                            <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{element.rooms}<sup></sup></span>
                                                                        </div>
                                                                        <div className="col-lg-2 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                            <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{isMediacore ? (element.availability.toLowerCase() === 'disponible' ? 'Si' : 'No') : <>{`${element.surface}m`}<sup>2</sup></>  } </span>
                                                                        </div>
                                                                        <div className="col-lg-2 col-2 px-0 px-lg-2" style={{textAlign:"center"}}>
                                                                            <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface_total} m<sup>2</sup></span>
                                                                        </div>
                                                                        <div className="col-lg-2 col-3 d-lg-block" style={{textAlign:'center'}}>
                                                                            <span className="unity-font montserrat-regular proyect-color ms-lg-0 me-lg-0" style={{textAlign:"center"}}>
                                                                                {element.operations.map((o,index) => (
                                                                                    <>{getShortOperation(o.operation_type)} <div className={index === element.operations.length - 1 ? 'd-none' : ''}><br /></div></>
                                                                                ))}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-lg-2 col-3 px-0 px-lg-2" style={{textAlign:"center"}}>
                                                                            <span className="unity-font montserrat-regular proyect-color" style={{minWidth:'5rem',textAlign:"center"}}>
                                                                                {console.log(element)}
                                                                                {element.show_price || element.web_price ? element.operations.map((o,index) => (
                                                                                    <>{`${o.prices.currency} ${Intl.NumberFormat("de-DE").format(o.prices.price)}`} <div className={index === element.operations.length - 1 ? 'd-none' : ''}><br /></div></>
                                                                                )):'A Consultar'}
                                                                                {/* {element.currency} {element.price > 0 ? new Intl.NumberFormat("de-DE").format(element.price) : element.price} */}
                                                                            </span>
                                                                        </div>
                                                                        {/* <div className="col-2 col-lg-1 my-auto round"><Link to={("/propiedad/" + element.operations[0]?.operation_type?.toLowerCase() + "/" + element.fake_address.toLowerCase().replaceAll(" ","-") + "/" + element.id)} className="icon-arrow"></Link></div> */}
                                                                    </div>
                                                                </a>
                                                            </>
                                                        </div>    
                                                    )
                                                )}
                                                </div>
                                            </div>
                                        ))
                                    :''
                                    }
                                    <div className={"col-10 m-auto text-center" + (group.length === 0 ? '  ' : " d-none") }>
                                        <p className="disabled-unities text-center" style={{fontSize:"1rem",margin: "0rem 0", maxWidth: ""}}>No hay resultados para tu busqueda.</p>
                                    </div>
                                </>
                            }
                        </div>
                    </div>  
                </>
                <div className="row contacto-main">
                    <div className="col-lg-6 col-12 intro">
                        <h2 className="mb-4">Consultanos por <br className="d-none d-lg-inline-block" /> este emprendimiento </h2>
                        {/* <p className="mt-lg-5">
                            Si ya tenés una propiedad en mente, no dudes<br /> 
                            en consultarnos. Con gusto responderemos<br /> 
                            todas tus dudas y te guiaremos durante
                            el proceso.
                        </p> */}
                    </div>
                    <div className="col-lg-6 col-12">
                        <Formulario development={allDevelopmentData} from={'development'} />
                        <div id="alert-message"></div>
                    </div>
                </div>
            </div>

            
        </section>
    )
}

export default connect(state => ({
    unities: state.developments.unities,
    development: state.developments.development,
    loading: state.developments.loading,
}),null)(Units);